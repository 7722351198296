import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextArea from './Common/Inputs/TextArea';

import { saveUserAnswers } from '../actions/UserInfo';

import './QuestionForm.scss';

class QuestionForm extends Component {
	state = { answer: '' };

	constructor(props) {
		super(props);
		this.state = { answer: this.getAnswerForQuestion(this.props.question) };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.question !== this.props.question) {
			this.hasChanged = false;
			this.setState({ answer: this.getAnswerForQuestion(nextProps.question) });
		}
	}

	getAnswerForQuestion = question => {
		const { answers } = this.props;
		const answer = answers[question._id] || '';
		this.checkBounds(answer);
		return answer;
	};

	saveAnswer = answer => {
		if( this.hasChanged ){
			const { user, question } = this.props;

			let answers = {};
			answers[question._id] = answer;

			this.props.saveUserAnswers({ user: user, question: question._id, answer: answer });
		}
	};

	setAnswer = answer => {
		this.checkBounds(answer);
		this.setState({answer: answer});
		this.hasChanged = true;
	};

	checkBounds = answer => {
		if( answer.length > 0 && this.state.answer.length === 0){
			this.props.isAnswering();
		}else if(answer.length === 0 && this.state.answer.length > 0){
			this.props.stoppedAnswering();
		}
	};

	render() {
		const { question } = this.props;
		let answer = this.state.answer || '';
		return (
			<div className="QuestionForm" onClick={this.onSelectChange}>
				<h1 className="QuestionForm-title">Question #{question.number}</h1>
				<h2 className="QuestionForm-question">{question.text}</h2>
				<div className="QuestionForm-answer">
					<TextArea 
						key={question._id}
						value={answer}
						onChange={this.setAnswer}
						onBlur={this.saveAnswer}
					/>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	user: state.Users.user,
	answers: state.Users.answers,
});

const mapDispatchToProps = dispatch => ({
	saveUserAnswers: payload => dispatch(saveUserAnswers(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(QuestionForm);
