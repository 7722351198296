import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
	initChatChannel,
	closeChatChannel,
} from '../actions/Chats';

import { 
	initMatchesChannel,
	closeMatchesChannel,
} from '../actions/Matches';

import { 
	getUserIdentification,
	getUserLookingFor,
	getUserAnswers,
	getUserLocation,
	getUserMatches,
	getUserChats,
} from '../actions/UserInfo';

class LoadUserInfo extends Component {

	componentDidMount() {
		const { user } = this.props;
		const user_id = user._id;

		this.props.initChatChannel();
		this.props.initMatchesChannel();
		this.props.getUserIdentification(user_id);
		this.props.getUserLookingFor(user_id);
		this.props.getUserAnswers(user_id);
		this.props.getUserLocation(user_id);
		this.props.getUserMatches(user_id);
		this.props.getUserChats(user_id);
	}

	componentWillUnmount() {
		this.props.closeChatChannel();
		this.props.closeMatchesChannel();
	}

	render() {
		return (<div className="LoadUserInfo" />);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({
	initChatChannel: () => dispatch(initChatChannel()),
	closeChatChannel: () => dispatch(closeChatChannel()),
	initMatchesChannel: () => dispatch(initMatchesChannel()),
	closeMatchesChannel: () => dispatch(closeMatchesChannel()),
	getUserIdentification: payload => dispatch(getUserIdentification(payload)),
	getUserLookingFor: payload => dispatch(getUserLookingFor(payload)),
	getUserAnswers: payload => dispatch(getUserAnswers(payload)),
	getUserLocation: payload => dispatch(getUserLocation(payload)),
	getUserMatches: payload => dispatch(getUserMatches(payload)),
	getUserChats: payload => dispatch(getUserChats(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LoadUserInfo);
