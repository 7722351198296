import { createActions } from 'redux-actions';
import { 
	SET_USER_INFO,
	GET_USER_LOOKING_FOR,
	SET_USER_LOOKING_FOR,
	PATCH_USER_LOOKING_FOR,
	SAVE_USER_LOOKING_FOR,
	GET_USER_IDENTIFICATION,
	SET_USER_IDENTIFICATION,
	PATCH_USER_IDENTIFICATION,
	SAVE_USER_IDENTIFICATION,
	SET_USER_AGE,
	SET_USER_EMAIL,
	GET_USER_ANSWERS,
	SET_USER_ANSWERS,
	SAVE_USER_ANSWERS,
	GET_USER_LOCATION,
	SET_USER_LOCATION,
	SAVE_USER_LOCATION,
	GET_USER_MATCHES,
	SET_USER_MATCHES,
	GET_USER_CHATS,
	SET_USER_CHATS,
	GET_USER_FEED,
	SET_USER_FEED,
	INCREMENT_UNSEEN_MATCHES,
	CLEAR_UNSEEN_MATCHES,
	GET_USER,
	GET_USER_BY_ID_AND_TOKEN,
	SAVE_USER,
	DELETE_ACCOUNT,
	REPORT_USER,
	GET_AUTH_TOKEN,
	SET_AUTH_TOKEN,
	SAVE_AUTH_TOKEN,
	DELETE_AUTH_TOKEN,
} from '../constants/Actions';

export const { setUserInfo } = createActions(SET_USER_INFO);

export const { getUserIdentification } = createActions(GET_USER_IDENTIFICATION);
export const { setUserIdentification } = createActions(SET_USER_IDENTIFICATION);
export const { patchUserIdentification } = createActions(PATCH_USER_IDENTIFICATION);
export const { saveUserIdentification } = createActions(SAVE_USER_IDENTIFICATION);

export const { getUserLookingFor } = createActions(GET_USER_LOOKING_FOR);
export const { setUserLookingFor } = createActions(SET_USER_LOOKING_FOR);
export const { patchUserLookingFor } = createActions(PATCH_USER_LOOKING_FOR);
export const { saveUserLookingFor } = createActions(SAVE_USER_LOOKING_FOR);

export const { setUserAge } = createActions(SET_USER_AGE);

export const { setUserEmail } = createActions(SET_USER_EMAIL);

export const { getUserAnswers } = createActions(GET_USER_ANSWERS);
export const { setUserAnswers } = createActions(SET_USER_ANSWERS);
export const { saveUserAnswers } = createActions(SAVE_USER_ANSWERS);

export const { getUserLocation } = createActions(GET_USER_LOCATION);
export const { setUserLocation } = createActions(SET_USER_LOCATION);
export const { saveUserLocation } = createActions(SAVE_USER_LOCATION);

export const { getUserMatches } = createActions(GET_USER_MATCHES);
export const { setUserMatches } = createActions(SET_USER_MATCHES);

export const { getUserChats } = createActions(GET_USER_CHATS);
export const { setUserChats } = createActions(SET_USER_CHATS);

export const { getUserFeed } = createActions(GET_USER_FEED);
export const { setUserFeed } = createActions(SET_USER_FEED);

export const { incrementUnseenMatches } = createActions(INCREMENT_UNSEEN_MATCHES);
export const { clearUnseenMatches } = createActions(CLEAR_UNSEEN_MATCHES);

export const { getUser } = createActions(GET_USER);
export const { getUserByIdAndToken } = createActions(GET_USER_BY_ID_AND_TOKEN);
export const { saveUser } = createActions(SAVE_USER);
export const { deleteAccount } = createActions(DELETE_ACCOUNT);
export const { reportUser } = createActions(REPORT_USER);

export const { getAuthToken } = createActions(GET_AUTH_TOKEN);
export const { saveAuthToken } = createActions(SAVE_AUTH_TOKEN);
export const { setAuthToken } = createActions(SET_AUTH_TOKEN);
export const { deleteAuthToken } = createActions(DELETE_AUTH_TOKEN);


