import React, { Component } from 'react';
import { connect } from 'react-redux';

import Selectable from './Common/Selectable';
import Button from './Common/Buttons/Button';

import { saveUserLookingFor } from '../actions/UserInfo';

import CONFIG from 'config.js';

import './LookingFor.scss';

class LookingFor extends Component {
	constructor(props) {
		super(props);
		this.options = CONFIG.identificationOptions;
	}

	onSelectChange = name => {
		const { user, lookingFor } = this.props;
		const value = !lookingFor[name];
		this.props.saveUserLookingFor({ user: user, lookingFor: name, value: value });
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		let options = Object.keys(this.options).map((key, index) => {
			let value = this.options[key];
			return (
				<span key={index} className="LookingFor-option">
					<Selectable 
						selected={this.props.lookingFor[key]}
						onSelectChange={this.onSelectChange}
						name={key}
					>
						{value}
					</Selectable>
				</span>
			);
		});
		return (
			<div className="LookingFor">
				<h1 className="LookingFor-header">What are you looking for?</h1>
				<h2 className="LookingFor-subhead">(Select all that apply)</h2>
				{ options }
				<Button
					text="Done"
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	lookingFor: state.Users.lookingFor,
});

const mapDispatchToProps = dispatch => ({
  saveUserLookingFor: payload => dispatch(saveUserLookingFor(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LookingFor);
