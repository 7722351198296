import { call, put, takeEvery } from 'redux-saga/effects';

import {
	setQuestions,
} from '../actions/Questions';

import {
	GET_QUESTIONS,
} from '../constants/Actions';

import * as QuestionsAPI from '../services/QuestionsAPI';

function* fetchQuestionsWorker() {
	try {
		const questions = yield call(QuestionsAPI.getQuestions);
		yield put(setQuestions(questions));
	} catch (e) {
		console.error("Error getting questions:");
		console.error(e.message);
		// yield put(getQuestionsFailed(e.message));
	}
}

function* questionsListener() {
	yield takeEvery(GET_QUESTIONS, fetchQuestionsWorker);
}

export default questionsListener;
