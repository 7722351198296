import React, { Component } from 'react';
import { connect } from 'react-redux';
import Geocode from "react-geocode";

import Button from './Common/Buttons/Button';

import { saveUserLocation } from '../actions/UserInfo';

import CONFIG from 'config.js';

import './AllowLocationButton.scss';

class AllowLocationButton extends Component {

	constructor(props) {
		super(props);

		Geocode.setApiKey(CONFIG.googleMapsApiKey);
		Geocode.enableDebug();
	}

	getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.addCityToLocationAndSave);
		}
	};

	addCityToLocationAndSave = locationObj => {
		const coords = locationObj.coords || {};
		const lat = coords.latitude;
		const lng = coords.longitude;
		if( !lat || !lng ){
			this.saveLocation(locationObj);
		}else{
			Geocode.fromLatLng(lat, lng).then(
				response => {
					const firstResult = response.results[0] || {};
					const { city, state } = this.getCityStateFromAddressResult(firstResult);
					locationObj.city = city;
					locationObj.state = state;
					this.saveLocation(locationObj);
				},
				error => {
					console.error("Geocode error:", error);
					this.saveLocation(locationObj);
				}
			);
		}
	};

	getCityStateFromAddressResult = address => {
		const addressComponents = address.address_components || [];
		let loc = {};
		for (let i = 0; i < addressComponents.length; i++) {
			const ac = addressComponents[i];
			if(ac.types.includes('sublocality') || ac.types.includes('locality')) {
				loc.city = ac.long_name;
			}
			else if(ac.types.includes('administrative_area_level_1')) {
				loc.state = ac.short_name;
			}
			else if(ac.types.includes('country')) {
				loc.country = ac.long_name;
				loc.registered_country_iso_code = ac.short_name;
			}
		};
		return loc;
	};

	saveLocation = locationObj => {
		const { user } = this.props;
		console.log("saved Location:");
		console.log(locationObj);
		this.props.saveUserLocation({ user: user, location: locationObj });
	};

	render() {
		const buttonText = this.props.buttonText || "allow";
		const buttonClasses = this.props.buttonClasses || 'small';
		return (
			<span className="AllowLocationButton">
				<Button
					text={buttonText}
					classes={buttonClasses}
					onClick={this.getLocation}
				/>
			</span>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	location: state.Users.location,
});

const mapDispatchToProps = dispatch => ({
  saveUserLocation: payload => dispatch(saveUserLocation(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AllowLocationButton);
