import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';
import Questions from './Questions';

import './Answers.scss';

class Answers extends Component {

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		return (
			<div className="Answers">
				<Questions />
				<Button
					text="Done"
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Answers);
