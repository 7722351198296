import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './Header';
import Message from './Message';
import UserInfo from './UserInfo';
import ReportUser from './ReportUser';

import TextArea from './Common/Inputs/TextArea';
import Button from './Common/Buttons/Button';

import { 
	sendChatMessage,
	setActiveChat,
	clearActiveChat,
} from '../actions/Chats';

import './Chatroom.scss';

class Chatroom extends Component {

	constructor(props) {
		super(props);
		this.chatId = this.props.match.params.id;
		this.state = { message: '' };
		this.setScrollPosition();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.chats !== this.props.chats) {
			this.updateActiveChat();
		}
	}

	componentDidMount() {
		this.setScrollPosition();
		this.updateActiveChat();
	}

	componentDidUpdate() {
		this.setScrollPosition();
	}

	componentWillUnmount() {
		this.props.clearActiveChat();
	}

	updateActiveChat = () => {
		if(this.chatId && this.chatId !== this.props.activeChat){
			this.props.setActiveChat(this.chatId);
		}
	};

	setScrollPosition = () => {
		if(this.messagesContainer){
			this.messagesContainer.scrollTop = 999999;
		}
	};

	setMessage = message => {
		this.setState({ message: message });
	};

	sendMessage = () => {
		const { user } = this.props;
		const { message } = this.state;
		const chat = this.getChat();
		this.textArea.focus();
		const newMessage = { user_id: user._id, message: message };
		const messageObj = {
			chat: chat.namespace,
			chat_id: this.chatId,
			message: newMessage,
		};
		this.props.sendChatMessage(messageObj);
		this.setMessage('');
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.sendMessage();
		}
	};

	getChat = () => {
		const { chats } = this.props;
		return chats[this.chatId] || {};
	};

	render() {
		const { user } = this.props;
		const chat = this.getChat();
		const messages = chat.messages || [];

		const messagesDisplay = messages.map((message, index) => {
			const classes = message.user_id === user._id ? 'you' : 'them';
			return (
				<Message key={index} text={message.message} classes={classes} />
			);
		});

		const emptyChatMessage = messages.length > 0 ? '' : 
			(<span>Be the first to send a message!</span>);

		const otherUser = chat.otherUser || {};

		return (
			<div className="Chatroom" onKeyPress={this.handleKeyPress}>
				<Header messages={true} />
				<div className="Chatroom-body">
					<div className="Chatroom-content">
						<h1 className="Chatroom-header">
							Chat with <UserInfo user={otherUser} />
							<ReportUser accusedUser={otherUser}/>
						</h1>
						<div
							className="Chatroom-messages" 
							ref={element => (this.messagesContainer = element)}
						>
							{ messagesDisplay }
							{ emptyChatMessage }
						</div>
						<div className="Chatroom-textarea">
							<TextArea 
								value={this.state.message || ''}
								onChange={this.setMessage}
								inputRef={input => (this.textArea = input)}
							/>
						</div>
						<Button
							text='Send'
							onClick={this.sendMessage}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	chats: state.Chats.chats,
	activeChat: state.Chats.activeChat,
});

const mapDispatchToProps = dispatch => ({
	sendChatMessage: payload => dispatch(sendChatMessage(payload)),
	setActiveChat: payload => dispatch(setActiveChat(payload)),
	clearActiveChat: payload => dispatch(clearActiveChat(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Chatroom);
