import React, { Component } from 'react';

import './Popup.scss';

export class Popup extends Component {
  constructor(props) {
    super(props);
    this.showHide(this.props);
  }

  handleClick = e => {
    if (!e.target.contains(this.popupWrapper) && !e.target.contains(this.popupButton)) {
      return;
    }
    e.stopPropagation();

    if (this.props.disableAutoClose) {
      this.props.onRequestClose && this.props.onRequestClose(e);
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    this.show = !this.show;
    this.forceUpdate();
  };

  showHide = props => (this.show = props.shown || props.show || false);

  componentWillReceiveProps(nextProps) {
    this.showHide(nextProps);
  }

  render() {
    if (!this.show) return null;

    return (
      <span
        className='Popup'
        onClick={this.handleClick}
        ref={element => (this.popupWrapper = element)}
      >
        <span className='Popup-contents'>
          {this.props.children}
          {this.props.button && (
            <div onClick={this.handleClick} />
          )}
        </span>
      </span>
    );
  }
}

export default Popup;
