import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';
import TextArea from './Common/Inputs/TextArea';

import { reportUser } from '../actions/UserInfo';

import './ReportUserForm.scss';

class ReportUserForm extends Component {

	state = { reason: '' };

	handleReportUser = e => {
		const { user, accusedUser } = this.props;
		const { reason } = this.state;
		this.props.reportUser({ reportingUser: user, accusedUser: accusedUser, reason: reason });
		this.props.close();
	};

	cancel = e => {
		this.props.close();
	};

	setReason = reason => {
		this.setState({ reason: reason });
	};

	render() {
		const { accusedUser } = this.props;

		return (
			<div className="ReportUserForm">
				<h1>Reporting {accusedUser.firstName}</h1>
				<h2>Tell us why:</h2>
				<TextArea 
					key="reason"
					value={this.state.reason}
					onChange={this.setReason}
					inputRef={input => (this.textArea = input)}
					maxLength={320}
				/>
				<div className="ReportUserForm-buttons">
					<Button
						text="Submit"
						classes='large'
						onClick={this.handleReportUser}
					/>
					<Button
						text="Cancel"
						classes='large'
						onClick={this.cancel}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({
	reportUser: payload => dispatch(reportUser(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReportUserForm);
