import React, { Component } from 'react';
import { connect } from 'react-redux';

import './SiteMain.scss';

class SiteMain extends Component {

	render() {
		return (
			<div className="SiteMain">
				Coming February 14th
			</div>
		);
	}
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteMain);
