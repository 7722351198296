import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'

import Login from './Login';
import LoggedIn from './LoggedIn';
import FbTokenLogin from './FbTokenLogin';

import './Main.scss';

class Main extends Component {

	constructor(props) {
		super(props);
		const queries = queryString.parse(this.props.location.search);
		this.token = queries.token;
		this.accessToken = queries.accessToken;
	}

	renderWithContainer = content => {
		return (
			<div className="main">
				{content}
			</div>
		);
	}

	render() {
		console.log("render Main");
		console.log(this.token);
		console.log(this.props.token);
		console.log(this.accessToken);
		let user = this.props.user;
		console.log("Main user:");
		console.log(user);
		if(user && user.name){
			return this.renderWithContainer(<LoggedIn />);
		}

		if((this.token && !this.props.token) || this.accessToken){
			return this.renderWithContainer(<FbTokenLogin {...this.props} />);
		}

		return this.renderWithContainer(<Login />);
	}
}

const mapStateToProps = state => ({
  user: state.Users.user,
	token: state.Tokens.authToken,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);
