import { handleActions } from 'redux-actions';

import {
	NEW_MATCHES,
	SET_USER_MATCHES,
	INCREMENT_UNSEEN_MATCHES,
	CLEAR_UNSEEN_MATCHES,
} from '../constants/Actions';

const defaultState = {
	matches: [],
	unseenMatches: 0,
};

const reducerMap = {};

reducerMap[SET_USER_MATCHES] = (state, action) => {
	return ({
		...state,
		matches: action.payload,
	});
};

reducerMap[NEW_MATCHES] = (state, action) => {
	const payload = action.payload || {};
	const matchesRecord = payload.matches;
	let matches = [];
	let unseenMatches = state.unseenMatches;
	if(matchesRecord.length > 0){
		matches = matchesRecord[0].users;
		unseenMatches++;
	}
	return ({
		...state,
		matches: matches,
		unseenMatches: unseenMatches,
	});
};

reducerMap[INCREMENT_UNSEEN_MATCHES] = (state, action) => ({
	...state,
	unseenMatches: state.unseenMatches + 1,
});

reducerMap[CLEAR_UNSEEN_MATCHES] = (state, action) => ({
	...state,
	unseenMatches: 0,
});


const matches = handleActions(reducerMap, defaultState);

export default matches;
