import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';

import Main from './Main';
import Privacy from './Site/Privacy';
import SiteMain from './Site/SiteMain';

// import Login from './Login';

class GlobalRouter extends Component {
  render () {
    // const { user } = this.props;
    // if(!(user && user.name)){
    //   console.log("GlobalRouter log out");
    //   return <Login />;
    // }
    console.log("render GlobalRouter");
    return (
      <div className="GlobalRouter">
        <Router>
          <Switch>
            <Route path="/privacy" component={Privacy} />
            <Route path="/app" component={Main} />
            <Route path="/" component={SiteMain} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalRouter);
