import React, { Component } from 'react';

import './Button.scss';

export default class Button extends Component {

	render() {
		var { children, text, classes } = this.props;
		return (
			<button className={'Button ' + classes} {...this.props}>
				{children || text}
			</button>
		);
	}
}
