import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Button from './Button';

export default class NavButton extends Component {

	render() {
		const { to, text } = this.props;
		return (
			<div className="NavButton">
				<NavLink to={to || '/'}>
					<Button
						text={text || 'Navigate'}
						classes='medium'
					/>
				</NavLink>
			</div>
		);
	}
}

