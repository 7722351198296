import {
	HEADERS_NO_AUTH,
	HEADERS_WITH_AUTH,
} from '../constants/Services';

export function getRequest(endpoint, token) {

	token = token || localStorage.getItem('id_token');

	const requestInit = {
		method: 'GET',
		headers: HEADERS_WITH_AUTH(token),
	};

	console.log("getRequest");
	console.log(requestInit);

	return fetch(endpoint, requestInit)
		.then(response => {
			console.log("response is:");
			console.log(response);
			if (response.status === 200) {
				return response.json();
			} else {
				return null;
			}
		});
}

export function patchRequest(endpoint, body, token) {

	token = token || localStorage.getItem('id_token');

	const requestInit = {
		method: 'PATCH',
		headers: HEADERS_WITH_AUTH(token),
		body: body,
	};

	return fetch(endpoint, requestInit);
}


export function postRequestNoAuth(endpoint, body) {
	const requestInit = {
		method: 'POST',
		headers: HEADERS_NO_AUTH,
		body: body,
	};

	return fetch(endpoint, requestInit);
}


export function putRequest(endpoint, body, token) {

	token = token || localStorage.getItem('id_token');

	const requestInit = {
		method: 'PUT',
		headers: HEADERS_WITH_AUTH(token),
		body: body,
	};
	
	return fetch(endpoint, requestInit);
}


export function deleteRequest(endpoint, body, token) {

	token = token || localStorage.getItem('id_token');

	const requestInit = {
		method: 'DELETE',
		headers: HEADERS_WITH_AUTH(token),
		body: body,
	};

	return fetch(endpoint, requestInit);
}


// export function getRequestNoAuth(endpoint, handleResponse) {
// 	const requestInit = {
// 		method: 'GET',
// 		headers: HEADERS_NO_AUTH,
// 		// credentials: 'same-origin',
// 	};

// 	return fetch(endpoint, requestInit)
// 		.then(response => {
// 			if (response.status === 200) {
// 				return response.json();
// 			} else {
// 				return null;
// 			}
// 		});
// }

// export function patchRequestNoAuth(endpoint, body) {
// 	const requestInit = {
// 		method: 'PATCH',
// 		headers: HEADERS_NO_AUTH,
// 		body: body,
// 		// credentials: 'include',
// 	};

// 	return fetch(endpoint, requestInit);
// }

// export function postRequestNoAuthWithHandler(endpoint, body, handleResponse) {
// 	const requestInit = {
// 		method: 'POST',
// 		headers: HEADERS_NO_AUTH,
// 		body: body,
// 		// credentials: 'include',
// 	};

// 	fetch(endpoint, requestInit)
// 		.then(response => response.json())
// 		.then(json => {
// 			handleResponse(json);
// 		});
// }

// export function putRequestNoAuthWithoutHandler(endpoint, body) {
// 	const requestInit = {
// 		method: 'PUT',
// 		headers: HEADERS_NO_AUTH,
// 		body: body,
// 		// credentials: 'include',
// 	};
	
// 	return fetch(endpoint, requestInit);
// }



