//headers for requests
export const HEADERS_NO_AUTH = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

export const HEADERS_WITH_AUTH = token => ({
	Accept: 'application/json',
	Authorization: 'Bearer ' + token,
	'Content-Type': 'application/json',
});