import { handleActions } from 'redux-actions';

import {
	SET_USER_INFO,
	SET_USER_IDENTIFICATION,
	PATCH_USER_IDENTIFICATION,
	SET_USER_LOOKING_FOR,
	PATCH_USER_LOOKING_FOR,
	SET_USER_AGE,
	SET_USER_EMAIL,
	SET_USER_ANSWERS,
	SET_USER_LOCATION,
	SET_USER_FEED,
	DELETE_ACCOUNT,
} from '../constants/Actions';

const defaultState = {
	user: {},
	identification: [],
	lookingFor: [],
	answers: {},
	answersAreSubstantial: false,
	location: {},
	feed: [],
	incomplete: {
		identification: true,
		lookingFor: true, 
		answers: true,
		location: true,
		age: true,
		email: true,
	},
};

const reducerMap = {};

reducerMap[SET_USER_INFO] = (state, action) => {
	const user = action.payload;
	if(!user){
		return state;
	}

	let newIncomplete = {...state.incomplete};
	if( user.age ){
		delete newIncomplete.age;
	}else{
		newIncomplete.age = true;
	}

	if( user.email ){
		delete newIncomplete.email;
	}else{
		newIncomplete.email = true;
	}

	return ({
		...state,
		user: {
			...user,
		},
		incomplete: newIncomplete,
	});
};

const setIdentification = (state, identification) => {

	const identificationComplete = Object.values(identification).reduce(
		( accumulator, current ) => {
			return accumulator || current;
		},
		false
	);

	let newIncomplete = {...state.incomplete};
	if( identificationComplete ){
		delete newIncomplete.identification;
	}else{
		newIncomplete.identification = true;
	}

	return ({
		...state,
		identification: identification,
		incomplete: newIncomplete,
	});

};

reducerMap[SET_USER_IDENTIFICATION] = (state, action) => {
	return setIdentification(state, action.payload);
};

reducerMap[PATCH_USER_IDENTIFICATION] = (state, action) => {
	const payload = action.payload;
	let newIdentification = {...state.identification};
	newIdentification[payload.identification] = payload.value;

	return setIdentification(state, newIdentification);
};

const setLookingFor = (state, lookingFor) => {

	const lookingForComplete = Object.values(lookingFor).reduce(
		( accumulator, current ) => {
			return accumulator || current;
		},
		false
	);

	let newIncomplete = {...state.incomplete};
	if( lookingForComplete ){
		delete newIncomplete.lookingFor;
	}else{
		newIncomplete.lookingFor = true;
	}

	return ({
		...state,
		lookingFor: lookingFor,
		incomplete: newIncomplete,
	});

};

reducerMap[SET_USER_LOOKING_FOR] = (state, action) => {
	return setLookingFor(state, action.payload);
};

reducerMap[PATCH_USER_LOOKING_FOR] = (state, action) => {
	const payload = action.payload;
	let newLookingFor = {...state.lookingFor};
	newLookingFor[payload.lookingFor] = payload.value;

	return setLookingFor(state, newLookingFor);
};

reducerMap[SET_USER_AGE] = (state, action) => {
	const age = action.payload.age;

	const ageObj = { age: age };
	const newUser = { ...state.user, ...ageObj };

	let newIncomplete = {...state.incomplete};
	if( age ){
		delete newIncomplete.age;
	}else{
		newIncomplete.age = true;
	}

	return ({
		...state,
		user: newUser,
		incomplete: newIncomplete,
	});
};

reducerMap[SET_USER_EMAIL] = (state, action) => {
	const email = action.payload.email;

	const emailObj = { email: email };
	const newUser = { ...state.user, ...emailObj };

	let newIncomplete = {...state.incomplete};
	if( email && typeof email === 'string' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ){
		delete newIncomplete.email;
	}else{
		newIncomplete.email = true;
	}

	return ({
		...state,
		user: newUser,
		incomplete: newIncomplete,
	});
};

reducerMap[SET_USER_ANSWERS] = (state, action) => {
	const answers = action.payload || {};
	const newAnswers = { ...state.answers, ...answers };
	const newAnswersFiltered = Object.keys(newAnswers)
		.filter(qid => newAnswers[qid] !== '')
		.reduce((obj, key) => {
			obj[key] = newAnswers[key];
			return obj;
		}, {});

	let newIncomplete = {...state.incomplete};
	// is the total char count of all answers > 30?
	const substantial = (Object.values(newAnswersFiltered).join('').length > 30);
	if( Object.keys(newAnswersFiltered).length >= 9 && substantial ){
		delete newIncomplete.answers;
	}else{
		newIncomplete.answers = true;
	}

	return ({
		...state,
		answers: newAnswersFiltered,
		answersAreSubstantial: substantial,
		incomplete: newIncomplete,
	});
};

reducerMap[SET_USER_LOCATION] = (state, action) => {
	const locationComplete = (action.payload && action.payload.coords);
	let newIncomplete = {...state.incomplete};
	if( locationComplete ){
		delete newIncomplete.location;
	}else{
		newIncomplete.location = true;
	}
	
	return ({
		...state,
		location: action.payload,
		incomplete: newIncomplete,
	});
};

reducerMap[SET_USER_FEED] = (state, action) => ({
	...state,
	feed: action.payload,
});

reducerMap[DELETE_ACCOUNT] = (state, action) => (defaultState);

const user = handleActions(reducerMap, defaultState);

export default user;
