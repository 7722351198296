import React, { Component } from 'react';
import { connect } from 'react-redux';

import IdentificationDisplay from './Common/Display/IdentificationDisplay';
import LocationDisplay from './Common/Display/LocationDisplay';

import ProfileSectionDisplay from './ProfileSectionDisplay';

import AllowLocationButton from './AllowLocationButton';

class ProfileSection extends Component {

	constructor(props) {
		super(props);

		this.initProfileSections(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.initProfileSections(nextProps);
	}

	initProfileSections = (props) => {
		const { user, identification, lookingFor, location } = props;
		this.profileSections = {
			lookingFor: {
				title: 'What are you looking for?', 
				title_completed: 'Looking for:',
				path: '/lookingfor',
				display: <IdentificationDisplay identification={lookingFor} verbose={true} />,
				completed_text: (
					<span>
						<span>Looking for matches who are </span>
						<IdentificationDisplay identification={lookingFor} verbose={true} />
					</span>
				),
			},
			age: {
				title: 'How old are you?', 
				path: '/age',
				display: <span>{user.age}</span>,
				completed_text: <span>{user.age} years old</span>,
			},
			email: {
				title: 'What is your email?', 
				path: '/email',
				display: <span>{user.email}</span>,
				completed_text: <span>{user.email}</span>,
			},
			identification: {
				title: 'Describe yourself',
				path: '/identification',
				display: <IdentificationDisplay identification={identification} verbose={true} />,
				completed_text: (
					<span>
						<span>You are </span>
						<IdentificationDisplay identification={identification} verbose={true} />
					</span>
				),
			},
			answers: {
				title: 'Answer nine questions',
				title_partial: 'Answer more questions',
				cta_partial: 'continue/edit',
				path: '/answers',
			},
			location: {
				title: 'Allow your location',
				button: <AllowLocationButton />,
				button_completed: <AllowLocationButton buttonText='update' />,
				display: <LocationDisplay location={location} />,
				completed_text: <span>Living in&nbsp;<LocationDisplay location={location} /></span>
			},
		};
	}

	render() {
		const { incomplete, type, answers, answersAreSubstantial } = this.props;
		if( !Object.keys(this.profileSections).includes(type) ){
			return null;
		}
		const complete = !Object.keys(incomplete).includes(type);
		const typeObj = this.profileSections[type];
		let collapsed = false;
		let ctaPath = typeObj.path;
		let button = typeObj.button;
		let title = typeObj.title;
		let cta = complete ? 'edit' : typeObj.cta_incomplete ? typeObj.cta_incomplete : 'answer';
		let status = !complete ? 'Incomplete' : typeObj.display ? typeObj.display : 'Completed';
		if(type === 'distance'){
			status = '5 Miles';
		}
		if(type === 'answers'){
			let answered = Object.keys(answers).length;
			if(answered > 0 && answered < 9){
				cta = typeObj.cta_partial;
				title = typeObj.title_partial;
			}
			if(!complete && answersAreSubstantial){
				status += ' (' + answered + '/9)';
			}else if(!answersAreSubstantial){
				title = "Write more meaningful answers."
			}else{
				const num = answered > 9 ? '9+' : answered;
				status = <span>{num} questions answered</span>;
			}
		}
		if(complete && ['identification', 'location', 'age', 'email', 'answers', 'lookingFor'].includes(type) ){
			collapsed = true;
		}
		if(complete){
			title = typeObj.title_completed ? typeObj.title_completed : title;
			button = typeObj.button_completed ? typeObj.button_completed : button;
			status = collapsed && typeObj.completed_text ? typeObj.completed_text : status;
		}
		return (
			<ProfileSectionDisplay 
				header={title}
				complete={complete} 
				status={status}
				cta={cta}
				ctaPath={ctaPath}
				button={button}
				collapsed={collapsed}
			/>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
  incomplete: state.Users.incomplete,
  answers: state.Users.answers,
  answersAreSubstantial: state.Users.answersAreSubstantial,
  identification: state.Users.identification,
	lookingFor: state.Users.lookingFor,
	location: state.Users.location,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSection);
