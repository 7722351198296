
import socketIOClient from "socket.io-client";

import CONFIG from 'config.js';

import {
	HEADERS_WITH_AUTH,
} from '../constants/Services';

export const SocketHelper = (function () {
	var socket;
 
	function initSocket() {
		return socketIOClient(CONFIG.socketServer);
	}
 
	return {
		getSocket: function () {
			if (!socket) {
				socket = initSocket();
			}
			return socket;
		},
		closeSocket: function () {
			if (socket) {
				socket.close();
			}
		},
		socketHeaders: function () {
			const token = localStorage.getItem('id_token');
			return HEADERS_WITH_AUTH(token);
		}
	};
})();

export const socketHeaders = SocketHelper.socketHeaders;
export const getSocket = SocketHelper.getSocket;
export const closeSocket = SocketHelper.closeSocket;

