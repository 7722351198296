import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'

import { 
	// saveAuthToken, 
	saveUser 
} from '../actions/UserInfo';

// import './FbTokenLogin.scss';

class FbTokenLogin extends Component {

	componentDidMount() {
		const user = queryString.parse(this.props.location.search);

		if( user.email && user.name ){
			this.props.saveUser(user);
		}

		// const token = queries.token;
		// const id = queries.id;
		// if(id && token){
		// 	this.props.getUserByIdAndToken({userID: id, token: token});
		// }
	}

	render() {
		console.log("render FbTokenLogin");
		return (
			<div className="FbTokenLogin" />
		);
	}
}

const mapStateToProps = state => ({
	// user: state.Users.user,
	// token: state.Tokens.authToken,
});

const mapDispatchToProps = dispatch => ({
	// saveAuthToken: payload => dispatch(saveAuthToken(payload)),
	// getUserByIdAndToken: payload => dispatch(getUserByIdAndToken(payload)),
	saveUser: payload => dispatch(saveUser(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FbTokenLogin);
