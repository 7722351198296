import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Distance.scss';

class Distance extends Component {

	render() {
		return (
			<div className="Distance">
				Distance
			</div>
		);
	}
}

const mapStateToProps = state => ({
  user: state.Users.user,
  incomplete: state.Users.incomplete,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Distance);
