
//User/Account/Auth
export const GET_USER = 'GET_USER';
export const GET_USER_BY_ID_AND_TOKEN = 'GET_USER_BY_ID_AND_TOKEN';
export const SAVE_USER = 'SAVE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

export const REPORT_USER = 'REPORT_USER';

export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SAVE_AUTH_TOKEN = 'SAVE_AUTH_TOKEN';
export const DELETE_AUTH_TOKEN = 'DELETE_AUTH_TOKEN';


// UserInfo
export const SET_USER_INFO = 'SET_USER_INFO';

export const GET_USER_IDENTIFICATION = 'GET_USER_IDENTIFICATION';
export const SET_USER_IDENTIFICATION = 'SET_USER_IDENTIFICATION';
export const PATCH_USER_IDENTIFICATION = 'PATCH_USER_IDENTIFICATION';
export const SAVE_USER_IDENTIFICATION = 'SAVE_USER_IDENTIFICATION';

export const GET_USER_LOOKING_FOR = 'GET_USER_LOOKING_FOR';
export const SET_USER_LOOKING_FOR = 'SET_USER_LOOKING_FOR';
export const PATCH_USER_LOOKING_FOR = 'PATCH_USER_LOOKING_FOR';
export const SAVE_USER_LOOKING_FOR = 'SAVE_USER_LOOKING_FOR';

export const SET_USER_AGE = 'SET_USER_AGE';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';

export const GET_USER_ANSWERS = 'GET_USER_ANSWERS';
export const SET_USER_ANSWERS = 'SET_USER_ANSWERS';
export const SAVE_USER_ANSWERS = 'SAVE_USER_ANSWERS';

export const GET_USER_LOCATION = 'GET_USER_LOCATION';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SAVE_USER_LOCATION = 'SAVE_USER_LOCATION';

export const GET_USER_MATCHES = 'GET_USER_MATCHES';
export const SET_USER_MATCHES = 'SET_USER_MATCHES';

export const GET_USER_CHATS = 'GET_USER_CHATS';
export const SET_USER_CHATS = 'SET_USER_CHATS';

export const INCREMENT_UNSEEN_MATCHES = 'INCREMENT_UNSEEN_MATCHES';
export const CLEAR_UNSEEN_MATCHES = 'CLEAR_UNSEEN_MATCHES';

export const GET_USER_FEED = 'GET_USER_FEED';
export const SET_USER_FEED = 'SET_USER_FEED';

// Questions
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const SET_QUESTIONS = 'SET_QUESTIONS';

//Chats
export const INIT_CHAT_CHANNEL = 'INIT_CHAT_CHANNEL';
export const CLOSE_CHAT_CHANNEL = 'CLOSE_CHAT_CHANNEL';
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE';
export const SUBSCRIBE_TO_CHAT = 'SUBSCRIBE_TO_CHAT';
export const INCREMENT_UNREAD_MESSAGES = 'INCREMENT_UNREAD_MESSAGES';
export const CLEAR_UNREAD_MESSAGES = 'CLEAR_UNREAD_MESSAGES';
export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const CLEAR_ACTIVE_CHAT = 'CLEAR_ACTIVE_CHAT';

//Matches
export const INIT_MATCHES_CHANNEL = 'INIT_MATCHES_CHANNEL';
export const CLOSE_MATCHES_CHANNEL = 'CLOSE_MATCHES_CHANNEL';
export const SEND_USER_LIKES = 'SEND_USER_LIKES';
export const SUBSCRIBE_TO_UPDATES = 'SUBSCRIBE_TO_UPDATES';
export const NEW_MATCHES = 'NEW_MATCHES';

