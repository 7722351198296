import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadUserInfo from './LoadUserInfo';
import LoadQuestions from './LoadQuestions';
import LoggedInRouter from './LoggedInRouter';

import './LoggedIn.scss';

class LoggedIn extends Component {

	render() {
		console.log("render LoggedIn");
		return (
			<div className="LoggedIn">
				<LoadUserInfo />
				<LoadQuestions />
				<LoggedInRouter />
			</div>
		);
	}
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoggedIn);
