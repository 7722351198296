import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/answers/';

export function getUserAnswers(user_id) {
	const params = encodeURIComponent(user_id);
	const endpoint = CONFIG.apiUrl + path + params;
	return RequestHelper.getRequest(endpoint);
}

export function submitUserAnswer(user, question, answer) {
	const endpoint = CONFIG.apiUrl + path;
	const body = JSON.stringify({user_id: user._id, question: question, answer: answer});
	return RequestHelper.putRequest(endpoint, body);
}