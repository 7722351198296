import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/feed/';

export function getUserFeed(user_id) {
	const params = encodeURIComponent(user_id);
	const endpoint = CONFIG.apiUrl + path + params;
	return RequestHelper.getRequest(endpoint);
}
