import React, { Component } from 'react';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login';

import Logo from './Common/Logo';

import CONFIG from 'config.js';

import { 
	getUser, 
	saveUser,
	getAuthToken, 
} from '../actions/UserInfo';

import './Login.scss';

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loggedIn: false,
			email: null,
			userID: null,
			name: null,
			firstName: null,
			lastName: null
		};
	}

	componentDidMount() {
		this.props.getAuthToken();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.token !== this.props.token) {
			console.log("we have a new token value");
		}
	}

	componentClicked = () => {
		console.log("facebook login clicked");
	};

	responseFacebook = (response) => {
		console.log("Login.responseFacebook");
		console.log("Response is:");
		console.log(response);
		if(response.userID){
			console.log("this.props.token");
			console.log(this.props.token);
			if(this.props.token){
				this.props.getUser(response);
			}else{
				this.props.saveUser(response);
			}
		}
	};

	renderFacebookLogin = (autoLoad) => {
		return (
			<div className="Login-button">
				<FacebookLogin
					appId={CONFIG.facebookAppId}
					autoLoad={autoLoad}
					fields="name,email,picture"
					scope="public_profile" //,user_friends,user_likes,user_gender
					onClick={this.componentClicked}
					callback={this.responseFacebook}
				/>
			</div>
		);
	};

	render() {
		const { loggedIn } = this.state;
		const { firstName, name } = this.props.user;
		const { token, hasCheckedForToken } = this.props;

		const content = (loggedIn && name) ? 
			(
				<div className="Login-greeting">
					Hello, {firstName || name}.
				</div>
			) : 
			(hasCheckedForToken) ? this.renderFacebookLogin(!!token) 
			: <span></span>;

		return (
			<div className="Login">
				<Logo />
				<div style={{width: '80%', wordBreak: 'break-all'}}>{token}</div>
				<div className="Login-content">
					{content}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	token: state.Tokens.authToken,
	hasCheckedForToken: state.Tokens.hasCheckedForToken,
});

const mapDispatchToProps = dispatch => ({
	getUser: payload => dispatch(getUser(payload)),
	saveUser: payload => dispatch(saveUser(payload)),
	getAuthToken: () => dispatch(getAuthToken()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Login);
