import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Button from './Common/Buttons/Button';

import Header from './Header';
import ProfileSection from './ProfileSection';

import './Profile.scss';

class Profile extends Component {

	render() {
		const { incomplete } = this.props;
		const isComplete = !(Object.keys(incomplete).length > 0);
		let title = isComplete ? '' : 'Complete';
		title += ' Your Profile';

		let header = isComplete ? (
			<Header messages={true} feed={true} />
		) : null;

		let viewMatches = isComplete ? (
			<div className="Profile-viewmatches">
				<NavLink to='/'>
					<Button
						text="View Matches"
						classes='large'
					/>
				</NavLink>
			</div>
		) : null;

		const profileSections = ['identification', 'age', 'location', 'email', 'lookingFor', 'answers'];

		const sections = profileSections.map((section, index) => {
			return (
				<ProfileSection key={index} type={section} />
			);
		});

		const deleteAccountButton = (
			<div className="Profile-deleteaccount">
				<NavLink to='/deleteaccount'>
					<Button
						text="Delete Account"
						classes='small'
					/>
				</NavLink>
			</div>
		);

		return (
			<div className="Profile">
				{header}
				<h1 className="Profile-title">{title}</h1>
				{sections}
				{viewMatches}
				{deleteAccountButton}
			</div>
		);
	}
}

const mapStateToProps = state => ({
  user: state.Users.user,
  incomplete: state.Users.incomplete,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
