import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/chats/';

export function getUserChats(user_id) {
	const params = encodeURIComponent(user_id);
	const endpoint = CONFIG.apiUrl + path + params;
	return RequestHelper.getRequest(endpoint);
}
