import { call, put, takeEvery } from 'redux-saga/effects';

import { setLoading, setLoaded } from '../actions/GlobalLoading';

import {
	setUserIdentification,
	patchUserIdentification,
	setUserLookingFor,
	patchUserLookingFor,
	setUserAnswers,
	setUserLocation,
	setUserMatches,
	setUserChats,
	setUserFeed,
} from '../actions/UserInfo';

import {
	subscribeToChat,
} from '../actions/Chats';

import {
	subscribeToUpdates,
} from '../actions/Matches';

import {
	GET_USER_IDENTIFICATION,
	SAVE_USER_IDENTIFICATION,
	GET_USER_LOOKING_FOR,
	SAVE_USER_LOOKING_FOR,
	GET_USER_ANSWERS,
	SAVE_USER_ANSWERS,
	GET_USER_LOCATION,
	SAVE_USER_LOCATION,
	GET_USER_MATCHES,
	GET_USER_CHATS,
	GET_USER_FEED,
	SET_USER_AGE,
	SET_USER_EMAIL,
} from '../constants/Actions';

import * as IdentificationAPI from '../services/IdentificationAPI';
import * as LookingForAPI from '../services/LookingForAPI';
import * as AnswersAPI from '../services/AnswersAPI';
import * as LocationAPI from '../services/LocationAPI';
import * as MatchesAPI from '../services/LikesAPI';
import * as ChatsAPI from '../services/ChatsAPI';
import * as UsersAPI from 'services/UsersAPI';
import * as FeedsAPI from 'services/FeedsAPI';

const handleGetError = (err, infoType) => {
	console.error("Error finding " + infoType	+ ":");
	console.error(err);
};

const handleSetError = (err, infoType) => {
	console.error("Error submitting " + infoType	+ ":");
	console.error(err);
};

function* fetchUserIdentificationWorker(action) {
	yield put(setLoading());
	try {
		const userIdentification = yield call(IdentificationAPI.getUserIdentification, action.payload);

		const newIdentification = userIdentification.reduce(
			( accumulator, current ) => {
				accumulator[current.identification] = current.value;
				return accumulator;
			},
			{}
		);

		yield put(setUserIdentification(newIdentification));
	} catch (e) {
		handleGetError(e, 'identification');
		// yield put(getUserIdentificationFailed(e.message));
	}
	yield put(setLoaded());
}

function* fetchUserLookingForWorker(action) {
	yield put(setLoading());
	try {
		const userLookingFor = yield call(LookingForAPI.getUserLookingFor, action.payload);

		const newLookingFor = userLookingFor.reduce(
			( accumulator, current ) => {
				accumulator[current.lookingfor] = current.value;
				return accumulator;
			},
			{}
		);

		yield put(setUserLookingFor(newLookingFor));
	} catch (e) {
		handleGetError(e, 'lookingfor');
		// yield put(getUserLookingForFailed(e.message));
	}
	yield put(setLoaded());
}

function* fetchUserAnswersWorker(action) {
	yield put(setLoading());
	try {
		const userAnswers = yield call(AnswersAPI.getUserAnswers, action.payload);

		const newAnswers = userAnswers.reduce(
			( accumulator, current ) => {
				accumulator[current.question] = current.answer;
				return accumulator;
			},
			{}
		);

		yield put(setUserAnswers(newAnswers));
	} catch (e) {
		handleGetError(e, "user's answers");
		// yield put(getUserAnswersFailed(e.message));
	}
	yield put(setLoaded());
}

function* fetchUserLocationWorker(action) {
	yield put(setLoading());
	try {
		const userLocation = yield call(LocationAPI.getUserLocation, action.payload);
		if(userLocation){
			yield put(setUserLocation(userLocation.location));
		}
	} catch (e) {
		handleGetError(e, 'location');
		// yield put(getUserLocationFailed(e.message));
	}
	yield put(setLoaded());
}

function* fetchUserMatchesWorker(action) {
	yield put(setLoading());
	try {
		const userMatches = yield call(MatchesAPI.getUserMatches, action.payload);

		if(userMatches.length > 0){
			let matches = userMatches[0].users;
			yield put(setUserMatches(matches));
		}
		yield put(subscribeToUpdates(action.payload));
	} catch (e) {
		handleGetError(e, 'user matches');
		// yield put(getUserLocationFailed(e.message));
	}
	yield put(setLoaded());
}

function* fetchUserChatsWorker(action) {
	try {
		const userChats = yield call(ChatsAPI.getUserChats, action.payload);

		if(userChats && userChats.length > 0){
			yield put(setUserChats(userChats));
			for(let i = 0; i < userChats.length; i++){
				const chat = userChats[i];
				if(chat !== {} && chat.namespace){
					yield put(subscribeToChat(chat));
				}
			}
		}
	} catch (e) {
		handleGetError(e, "user's chats");
		// yield put(getUserLocationFailed(e.message));
	}
}

function* fetchUserFeedWorker(action) {
	// yield put(setLoading());
	try {
		const userFeed = yield call(FeedsAPI.getUserFeed, action.payload);
		yield put(setUserFeed(userFeed));
	} catch (e) {
		handleGetError(e, "user's feed");
		// yield put(getUserFeedFailed(e.message));
	}
	// yield put(setLoaded());
}

function* setUsersAgeWorker(action) {
	try {
		yield call(UsersAPI.submitUsersAge, action.payload);
	} catch (e) {
		handleSetError(e, "user's age");
		// yield put(setUserAgeFailed(e.message));
	}
}

function* setUsersEmailWorker(action) {
	try {
		yield call(UsersAPI.submitUsersEmail, action.payload);
	} catch (e) {
		handleSetError(e, "user's email");
		// yield put(setUserEmailFailed(e.message));
	}
}

function* saveUserLocationWorker(action) {
	try {
		const payload = action.payload;
		yield call(LocationAPI.submitUserLocation, payload.user, payload.location);
	} catch (e) {
		handleSetError(e, "user location");
		// yield put(setUserLocationFailed(e.message));
	}
}

function* saveUserIdentificationWorker(action) {
	try {
		const payload = action.payload;
		yield call(IdentificationAPI.submitUserIdentification, payload.user, payload.identification, payload.value);
		yield put(patchUserIdentification(payload));
	} catch (e) {
		handleSetError(e, "user identification");
		// yield put(setUserIdentificationFailed(e.message));
	}
}

function* saveUserLookingForWorker(action) {
	try {
		const payload = action.payload;
		yield call(LookingForAPI.submitUserLookingFor, payload.user, payload.lookingFor, payload.value);
		yield put(patchUserLookingFor(payload));
	} catch (e) {
		handleSetError(e, "user lookingfor");
		// yield put(setUserLookingForFailed(e.message));
	}
}

function* saveUserAnswersWorker(action) {
	try {
		const payload = action.payload;
		yield call(AnswersAPI.submitUserAnswer, payload.user, payload.question, payload.answer);
		let newAnswers = {};
		newAnswers[payload.question] = payload.answer;
		yield put(setUserAnswers(newAnswers));
	} catch (e) {
		handleSetError(e, "user answer");
		// yield put(setUserAnswerFailed(e.message));
	}
}

function* userInfoListener() {
	yield takeEvery(GET_USER_IDENTIFICATION, fetchUserIdentificationWorker);
	yield takeEvery(GET_USER_LOOKING_FOR, fetchUserLookingForWorker);
	yield takeEvery(GET_USER_ANSWERS, fetchUserAnswersWorker);
	yield takeEvery(GET_USER_LOCATION, fetchUserLocationWorker);
	yield takeEvery(GET_USER_MATCHES, fetchUserMatchesWorker);
	yield takeEvery(GET_USER_CHATS, fetchUserChatsWorker);
	yield takeEvery(GET_USER_FEED, fetchUserFeedWorker);

	yield takeEvery(SET_USER_AGE, setUsersAgeWorker);
	yield takeEvery(SET_USER_EMAIL, setUsersEmailWorker);
	yield takeEvery(SAVE_USER_LOCATION, saveUserLocationWorker);
	yield takeEvery(SAVE_USER_IDENTIFICATION, saveUserIdentificationWorker);
	yield takeEvery(SAVE_USER_LOOKING_FOR, saveUserLookingForWorker);
	yield takeEvery(SAVE_USER_ANSWERS, saveUserAnswersWorker);
}

export default userInfoListener;
