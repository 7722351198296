import { createActions } from 'redux-actions';
import { 
	INIT_CHAT_CHANNEL,
	CLOSE_CHAT_CHANNEL,
	SUBSCRIBE_TO_CHAT,
	SEND_CHAT_MESSAGE,
	NEW_CHAT_MESSAGE,
	INCREMENT_UNREAD_MESSAGES,
	CLEAR_UNREAD_MESSAGES,
	SET_ACTIVE_CHAT,
	CLEAR_ACTIVE_CHAT,
} from '../constants/Actions';

export const { initChatChannel } = createActions(INIT_CHAT_CHANNEL);
export const { closeChatChannel } = createActions(CLOSE_CHAT_CHANNEL);
export const { subscribeToChat } = createActions(SUBSCRIBE_TO_CHAT);
export const { sendChatMessage } = createActions(SEND_CHAT_MESSAGE);
export const { newChatMessage } = createActions(NEW_CHAT_MESSAGE);
export const { incrementUnreadMessages } = createActions(INCREMENT_UNREAD_MESSAGES);
export const { clearUnreadMessages } = createActions(CLEAR_UNREAD_MESSAGES);
export const { setActiveChat } = createActions(SET_ACTIVE_CHAT);
export const { clearActiveChat } = createActions(CLEAR_ACTIVE_CHAT);



