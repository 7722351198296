import { handleActions } from 'redux-actions';

import {
  SET_AUTH_TOKEN,
  SAVE_AUTH_TOKEN,
  DELETE_AUTH_TOKEN,
} from '../constants/Actions';

const defaultState = {
  hasCheckedForToken: false,
  authToken: null,
};

const reducerMap = {};

reducerMap[SET_AUTH_TOKEN] = (state, action) => ({
  ...state,
  authToken: action.payload,
  hasCheckedForToken: true,
});

reducerMap[SAVE_AUTH_TOKEN] = (state, action) => ({
  ...state,
  authToken: action.payload,
  hasCheckedForToken: true,
});

reducerMap[DELETE_AUTH_TOKEN] = (state, action) => ({
  ...state,
  authToken: null,
  hasCheckedForToken: true,
});

const tokens = handleActions(reducerMap, defaultState);

export default tokens;
