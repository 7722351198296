import React, { Component } from 'react';
import { Provider } from 'react-redux';

import ConfigureStore from '../store/ConfigureStore';
import GlobalRouter from './GlobalRouter';
import GlobalLoader from './Common/GlobalLoader';

import './App.scss';

const store = ConfigureStore();

class App extends Component {

	render() {
		console.log("render App");
		return (
			<Provider store={store}>
				<div className="App">
					<GlobalLoader />
					<GlobalRouter />
				</div>
			</Provider>
		);
	}
}

export default App;
