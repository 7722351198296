import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Button from './Common/Buttons/Button';

import './ProfileSectionDisplay.scss';

export default class ProfileSectionDisplay extends Component {

	render() {
		const { header, complete, collapsed, status, cta, ctaPath, button } = this.props;
		const bullet = complete ? '✓' : '-'; //✔
		const completeStyle = complete ? 'complete' : '';
		const collapsedStyle = collapsed ? 'collapsed' : '';

		const ctaButton = button ? button : (
			<NavLink to={ctaPath || '/'}>
				<Button
					text={cta}
					classes='small'
				/>
			</NavLink>
		);

		return (
			<div className={"ProfileSectionDisplay " + completeStyle + ' ' + collapsedStyle}>
				<h2 className="ProfileSectionDisplay-header">{header}</h2>
				<div className="ProfileSectionDisplay-body">
					<span className="ProfileSectionDisplay-bullet">{bullet}</span>
					<span className="ProfileSectionDisplay-status">{status}</span>
					<span className="ProfileSectionDisplay-cta">
						{ ctaButton }
					</span>
				</div>
			</div>
		);
	}
}
