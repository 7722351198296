
import { eventChannel } from 'redux-saga';
import { call, put, takeEvery, take } from 'redux-saga/effects';

import {
	getSocket,
	closeSocket,
	socketHeaders,
} from '../services/SocketHelper';

import {
	newChatMessage,
} from '../actions/Chats';

import {
	INIT_CHAT_CHANNEL,
	CLOSE_CHAT_CHANNEL,
	SUBSCRIBE_TO_CHAT,
	SEND_CHAT_MESSAGE,
} from '../constants/Actions';


const subscribeToChat = chat => {
	const socket = getSocket();
	console.log("Subscribing to chat: ", chat.namespace);
	socket.emit("SubscribeToChat", chat.namespace);
};

const sendChatMessage = messageObj => {
	const socket = getSocket();
	socket.emit("SendMessage", messageObj, socketHeaders());
};

function createEventChannelWorker() {
	const socket = getSocket();
	return eventChannel(emit => {
		socket.on("NewMessage", messageObj => emit(messageObj));
		return () => {
			closeSocket();
		};
	});
}

function* initChatChannelWorker() {
	const channel = yield call(createEventChannelWorker);
	while (true) {
		const messageObj = yield take(channel);
		console.log("new messageObj:");
		console.log(messageObj);
		yield put(newChatMessage(messageObj));
	}
}

function* closeChatChannelWorker() {
	yield call(closeSocket);
}

function* subscribeToChatWorker(action) {
	try {
		yield call(subscribeToChat, action.payload);
	} catch (e) {
		console.error("Error subscribing to chat:");
		console.error(e);
	}
}

function* sendChatMessageWorker(action) {
	try {
		const messageObj = action.payload;
		yield call(sendChatMessage, messageObj);
		yield put(newChatMessage(messageObj));
	} catch (e) {
		console.error("Error sending chat:");
		console.error(e);
	}
}

function* chatsListener() {
	yield takeEvery(INIT_CHAT_CHANNEL, initChatChannelWorker);
	yield takeEvery(CLOSE_CHAT_CHANNEL, closeChatChannelWorker);
	yield takeEvery(SUBSCRIBE_TO_CHAT, subscribeToChatWorker);
	yield takeEvery(SEND_CHAT_MESSAGE, sendChatMessageWorker);
}

export default chatsListener;
