import React, { Component } from 'react';

import './TextArea.scss';

export default class TextArea extends Component {

	handleChange = ({ target: { value } }) => {
		this.props.onChange(value);
	};

	handleBlur = ({ target: { value } }) => {
		if (this.props.onBlur){
			this.props.onBlur(value);
		}
	};

	render() {
		const { value, inputRef, maxLength } = this.props;
		const showCaret = value.length > 0 ? '' : 'showFakeCaret';
		return (
			<div className={"TextArea " + showCaret}>
				<div className="TextArea-box">
					<div className="TextArea-caret"/>
					<textarea
						className="TextArea-textarea"
						maxLength={ maxLength || 365 }
						autoFocus={true}
						value={value}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						ref={inputRef}
					/>
				</div>
			</div>
		);
	}
}