import { handleActions } from 'redux-actions';

import {
	NEW_CHAT_MESSAGE,
	SET_USER_CHATS,
	INCREMENT_UNREAD_MESSAGES,
	CLEAR_UNREAD_MESSAGES,
	SET_ACTIVE_CHAT,
	CLEAR_ACTIVE_CHAT,
} from '../constants/Actions';

const defaultState = {
	chats: {},
	lastMessages: {},
	activeChat: null,
};

const reducerMap = {};

reducerMap[SET_USER_CHATS] = (state, action) => {
	let chats = action.payload;
	if(Array.isArray(chats)){
		let chatsObj = {};
		chats.forEach(chat => {
			chatsObj[chat._id] = chat;
		});
		chats = chatsObj;
	}
	return ({
		...state,
		chats: chats,
	});
};

reducerMap[NEW_CHAT_MESSAGE] = (state, action) => {
	const payload = action.payload || {};
	console.log("NEW_CHAT_MESSAGE");
	console.log(payload);
	console.log(state.chats);
	const chatId = payload.chat_id;
	const message = payload.message;

	let chat = state.chats[chatId] || {};
	const messages = chat.messages || [];
	const newMessages = messages.concat([message]);
	chat.messages = newMessages;

	const chatObj = {};
	chatObj[chatId] = chat;

	const newChats = { ...state.chats, ...chatObj };

	let newLastMessages = {...state.lastMessages};
	newLastMessages[chatId] = message;

	const unreadMessagesInc = (chatId === state.activeChat) ? 0 : 1;

	return ({
		...state,
		chats: newChats,
		lastMessages: newLastMessages,
		unreadMessages: state.unreadMessages + unreadMessagesInc,
	});
};


reducerMap[INCREMENT_UNREAD_MESSAGES] = (state, action) => ({
	...state,
	unreadMessages: state.unreadMessages + 1,
});

reducerMap[CLEAR_UNREAD_MESSAGES] = (state, action) => ({
	...state,
	unreadMessages: 0,
});

reducerMap[SET_ACTIVE_CHAT] = (state, action) => ({
	...state,
	activeChat: action.payload,
});

reducerMap[CLEAR_ACTIVE_CHAT] = (state, action) => ({
	...state,
	activeChat: null,
});

const chat = handleActions(reducerMap, defaultState);

export default chat;
