import React, { Component } from 'react';

import IdentificationDisplay from './Common/Display/IdentificationDisplay';
import LocationDisplay from './Common/Display/LocationDisplay';

import './UserInfo.scss';

export default class UserInfo extends Component {

	render() {
		const { user } = this.props;

		return (
			<div className="UserInfo">
				<span className="UserInfo-firstname">
					{user.firstName || 'User'}
				</span>
				<span className="UserInfo-age">
					{user.age || '??'}
				</span>
				<span className="UserInfo-identification">
					<IdentificationDisplay identification={user.identification} />
				</span>
				<span className="UserInfo-location">
					<LocationDisplay location={user.location} />
				</span>
			</div>
		);
	}
}
