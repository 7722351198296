import React, { Component } from 'react';
import Geocode from "react-geocode";

import CONFIG from 'config.js';

import './LocationDisplay.scss';

export default class LocationDisplay extends Component {

	constructor(props) {
		super(props);
		const location = this.props.location || {};
		this.state = {location: location.city || location.state || ''};

		Geocode.setApiKey(CONFIG.googleMapsApiKey);
		Geocode.enableDebug();

		if(location && !location.city && !location.state){
			this.getCityFromLatLng(location);
		}
	}

	componentDidMount() {
  	this.mounted = true;
  }

  componentWillUnmount() {
		this.mounted = false;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) {
			this.displayLocation(nextProps.location);
		}
	}

	displayLocation = locationObj => {
		if( locationObj.city ){
			this.setState({ location: locationObj.city});
		} else if( locationObj.state ){
			this.setState({ location: locationObj.state});
		} else{
			this.getCityFromLatLng(locationObj);
		}
	};

	getCityFromLatLng = locationObj => {
		const coords = locationObj.coords || {};
		const lat = coords.latitude;
		const lng = coords.longitude;
		if( !lat || !lng ){
			// this.setState({ location: ''});
		}else{
			Geocode.fromLatLng(lat, lng).then(
				response => {
					// this is an antipattern, but will work for now
					if(this.mounted){
						const firstResult = response.results[0] || {};
						const { city, state } = this.getCityStateFromAddressResult(firstResult);
						this.setState({ location: city || state });
					}
				},
				error => {
					console.error("Geocode error:", error);
				}
			);
		}
	};

	getCityStateFromAddressResult = address => {
		const addressComponents = address.address_components || [];
		let loc = {};
		for (let i = 0; i < addressComponents.length; i++) {
			const ac = addressComponents[i];
			if(ac.types.includes('sublocality') || ac.types.includes('locality')) {
				loc.city = ac.long_name;
			}
			else if(ac.types.includes('administrative_area_level_1')) {
				loc.state = ac.short_name;
			}
			else if(ac.types.includes('country')) {
				loc.country = ac.long_name;
				loc.registered_country_iso_code = ac.short_name;
			}
		};
		return loc;
	};

	render() {
		const { location } = this.state;

		return (
			<span className="LocationDisplay">
				{location}
			</span>
		);
	}
}
