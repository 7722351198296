import { createActions } from 'redux-actions';
import { 
	INIT_MATCHES_CHANNEL,
	CLOSE_MATCHES_CHANNEL,
	SUBSCRIBE_TO_UPDATES,
	SEND_USER_LIKES,
	NEW_MATCHES,
} from '../constants/Actions';

export const { initMatchesChannel } = createActions(INIT_MATCHES_CHANNEL);
export const { closeMatchesChannel } = createActions(CLOSE_MATCHES_CHANNEL);
export const { subscribeToUpdates } = createActions(SUBSCRIBE_TO_UPDATES);
export const { sendUserLikes } = createActions(SEND_USER_LIKES);
export const { newMatches } = createActions(NEW_MATCHES);



