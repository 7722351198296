import { handleActions } from 'redux-actions';

import {
  SET_QUESTIONS,
} from '../constants/Actions';

const defaultState = {
  questions: [],
};

const reducerMap = {};

reducerMap[SET_QUESTIONS] = (state, action) => ({
  ...state,
  questions: action.payload,
});

const questions = handleActions(reducerMap, defaultState);

export default questions;
