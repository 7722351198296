import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers/RootReducer';
import usersSaga from '../sagas/Users.saga';
import questionsSaga from '../sagas/Questions.saga';
import chatsSaga from '../sagas/Chats.saga';
import matchesSaga from '../sagas/Matches.saga';
import userInfoSaga from '../sagas/UserInfo.saga';

const sagaMiddleware = createSagaMiddleware();

function configureStore(initialState) {
	const store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));
	sagaMiddleware.run(usersSaga);
	sagaMiddleware.run(questionsSaga);
	sagaMiddleware.run(chatsSaga);
	sagaMiddleware.run(matchesSaga);
	sagaMiddleware.run(userInfoSaga);

	return store;
}

export default configureStore;
