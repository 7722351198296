import React, { Component } from 'react';

import './IntegerInput.scss';

export default class IntegerInput extends Component {

	handleChange = ({ target: { value } }) => {
		this.props.onChange(value);
	};

	handleBlur = ({ target: { value } }) => {
		if (this.props.onBlur){
			this.props.onBlur(value);
		}
	};

	render() {
		const { value, inputRef } = this.props;
		const showCaret = value ? '' : 'showFakeCaret';
		return (
			<div className={"IntegerInput " + showCaret}>
				<div className="IntegerInput-box">
					<div className="IntegerInput-caret"/>
					<input
						className="IntegerInput-input"
						type="number" 
						step="1"
						value={value}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						ref={inputRef}
					/>
				</div>
			</div>
		);
	}
}