import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavButton from './Common/Buttons/NavButton';

import './MessagesNavButton.scss';

class MessagesNavButton extends Component {

	render() {
		const { unreadMessages, unseenMatches } = this.props;
		let messagesText = 'Messages';
		if(unreadMessages > 0){
			messagesText = 'Messages (' + unreadMessages + ')';
		}
		if(unseenMatches > 0){
			messagesText = 'New Match!';
		}
		return (
			<NavButton to='/app/messages' text={messagesText} />
		);
	}
}

const mapStateToProps = state => ({
  unreadMessages: state.Chats.unreadMessages,
  unseenMatches: state.Matches.unseenMatches,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessagesNavButton);
