import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/reportusers/';

export function reportUser(payload) {
	const { reportingUser, accusedUser, reason } = payload;
	const endpoint = CONFIG.apiUrl + path;
	const bodyObj = {reportingUser: reportingUser._id, accusedUser: accusedUser._id, reason: reason};
	const body = JSON.stringify(bodyObj);
	return RequestHelper.putRequest(endpoint, body);
}

