import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/users/';

export function getUser(id) {
	console.log("UsersAPI getUser");
	const params = encodeURIComponent(id);
	const endpoint = CONFIG.apiUrl + path + params;
	console.log(endpoint);
	return RequestHelper.getRequest(endpoint);
}

export function getUserByIdAndToken(id, token) {
	console.log("UsersAPI getUserByIdAndToken");
	const params = encodeURIComponent(token);
	const endpoint = CONFIG.apiUrl + path + 'token/' + params;
	console.log(endpoint);
	return RequestHelper.getRequest(endpoint);
}

export function submitUser(user) {
	const endpoint = CONFIG.apiUrl + path;
	const body = JSON.stringify({user: user});
	return RequestHelper.postRequestNoAuth(endpoint, body);
}

export function submitUsersAge(payload) {
	const { user, age } = payload;
	return patchUser(user._id, {age: age});
}

export function submitUsersEmail(payload) {
	const { user, email } = payload;
	return patchUser(user._id, {email: email});
}

export function patchUser(id, patch) {
	const params = encodeURIComponent(id);
	const endpoint = CONFIG.apiUrl + path + params;
	const body = JSON.stringify(patch);
	return RequestHelper.patchRequest(endpoint, body);
}

export function deleteUser(user) {
	const params = encodeURIComponent(user._id);
	const endpoint = CONFIG.apiUrl + path + params;
	return RequestHelper.deleteRequest(endpoint);
}

