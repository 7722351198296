import React, { Component } from 'react';

import './Logo.scss';

export default class Logo extends Component {

	render() {
		const title = '9 Questions';
		const subhead = 'The text-only dating app.';

		return (
			<div className="Logo">
				<h1 className="Logo-header">{title}</h1>
				<div className="Logo-subheader">{subhead}</div>
			</div>
		);
	}
}
