import React, { Component } from 'react';
import { connect } from 'react-redux';

import PotentialMatches from './PotentialMatches';
import Header from './Header';

import { getUserFeed } from '../actions/UserInfo';

import { sendUserLikes } from '../actions/Matches';

import Profile from './Profile';

import './Feed.scss';

class Feed extends Component {

	constructor(props) {
		super(props);
		this.state = { currentlyViewing: 0 };
	}

	componentDidMount() {
		const { user } = this.props;
		this.props.getUserFeed(user._id);
	}

	setLikeDislike = (isLike) => {
		const { feed, user } = this.props;
		const { currentlyViewing } = this.state;
		// todo: out of range check
		const current = feed[currentlyViewing];

		if(!current){
			return;
		}

		const data = {
			user1: user._id,
			user2: current._id,
			likes: isLike,
		};
		this.props.sendUserLikes(data);
	};

	onLike = () => {
		this.setLikeDislike(true);
		this.nextSuitor();
	};

	onDislike = () => {
		this.setLikeDislike(false);
		this.nextSuitor();
	};

	nextSuitor = () => {
		this.setState({currentlyViewing: this.state.currentlyViewing + 1});
	};

	render() {
		const { feed, incomplete } = this.props;
		const { currentlyViewing } = this.state;
		const isComplete = !(Object.keys(incomplete).length > 0);

		if( !isComplete ){
			return <Profile />;
		}
		let body = (
			<span className="Feed-content">
				<div>There are no new matches near you.</div>
				<div>Check back in a few days!</div>
			</span>
		);
		if( feed && feed.length > 0 && feed.length > currentlyViewing){
			body = (
				<PotentialMatches 
					feed={feed} 
					current={currentlyViewing}
					onLike={this.onLike}
					onDislike={this.onDislike}
				/>
			);
		}
		return (
			<div className="Feed">
				<Header profile={true} messages={true}/>
				{ body }
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	feed: state.Users.feed,
	incomplete: state.Users.incomplete,
});

const mapDispatchToProps = dispatch => ({
	getUserFeed: payload => dispatch(getUserFeed(payload)),
	sendUserLikes: payload => dispatch(sendUserLikes(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Feed);
