import React, { Component } from 'react';

import Checkmark from '../Icons/Checkmark';

import './Checkbox.scss';

export default class Checkbox extends Component {

  render() {
    const { checked } = this.props;
    const checkedClass = checked ? 'checked' : '';
    return (
      <span className={"Checkbox " + checkedClass}>
        <span className="Checkbox-box" />
        <span className="Checkbox-checkmark">
          <Checkmark />
        </span>
      </span>
    );
  }
}