import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';
import AllowLocationButton from './AllowLocationButton';

import './Location.scss';

class Location extends Component {

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		const { location } = this.props;
		let body = <AllowLocationButton buttonText='Allow' buttonClasses='large' />;

		if(location && location.coords){
			body = <span>Location already allowed.</span>;
			console.log("Previously saved location:");
			console.log(location);
		}

		return (
			<div className="Location">
				<h1 className="Location-header">Allow your location</h1>
				{ body }
				<Button
					text="Back"
					classes='medium'
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	location: state.Users.location,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Location);
