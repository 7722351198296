import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';

import { deleteAccount } from '../actions/UserInfo';

import './DeleteAccount.scss';

class DeleteAccount extends Component {

	goBack = () => {
		this.props.history.goBack();
	};

	deleteAccount = () => {
		const { user } = this.props;
		this.props.deleteAccount(user);
	};

	render() {

		return (
			<div className="DeleteAccount">
				<h1 className="DeleteAccount-header">
					Are you sure you want to delete your account?
				</h1>
				<h2 className="DeleteAccount-subheader">
					This can't be undone.
				</h2>
				<Button
					text="Delete My Account"
					onClick={this.deleteAccount}
				/>
				<Button
					text="Back"
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({
  deleteAccount: payload => dispatch(deleteAccount(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DeleteAccount);
