import React, { Component } from 'react';

import './Message.scss';

export default class Message extends Component {

	render() {
		const { text, classes } = this.props;

		return (
			<div className={"Message " + classes}>
				<span className="Message-text">
					{text}
				</span>
			</div>
		);
	}
}
