import React, { Component } from 'react';

import Checkbox from './Inputs/Checkbox';

import './Selectable.scss';

export default class Selectable extends Component {

	onSelectChange = e => {
		const { name } = this.props;
		this.props.onSelectChange(name);
	};

	render() {
		const { selected, children, value } = this.props;
		return (
			<div className={"Selectable " + selected} onClick={this.onSelectChange}>
				<Checkbox checked={selected} />
				<span>{ value || children }</span>
			</div>
		);
	}
}
