import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';
import Popup from './Common/Popup';

import ReportUserForm from './ReportUserForm';

import './ReportUser.scss';

class ReportUser extends Component {
	constructor(props) {
		super(props);
		this.state = { showPopup: false };
	}

	togglePopup = () => {
		this.setState({showPopup: !this.state.showPopup });
	}

	render() {
		const { accusedUser } = this.props;

		return (
			<div className="ReportUser">
				<Button
					text={"Report " + accusedUser.firstName || "User"}
					classes='ReportUser-button small'
					onClick={this.togglePopup}
				/>
				<Popup show={this.state.showPopup} onClick={this.togglePopup}>
					<ReportUserForm accusedUser={accusedUser} close={this.togglePopup} />
				</Popup>
			</div>
		);
	}
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReportUser);
