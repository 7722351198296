let CONFIG = {};

const identificationOptions = {
	feminine: 'Female/Feminine', 
	masculine: 'Male/Masculine',
	nonbinary: 'Non-Binary',
	transgender: 'Transgender',
};

CONFIG = {
  apiUrl: 'https://www.9questions.app',
  socketServer: 'https://nine-questions-sockets.herokuapp.com', 
  facebookAppId: '213061262913758',
  googleMapsApiKey: 'AIzaSyDU4GKlirtMrgASauTBMqi9dFcu-KKge9I',
  identificationOptions: identificationOptions,
};

if(process.env.NODE_ENV === 'production'){
	CONFIG.facebookAppId = '325971191567241';
}else{
	CONFIG.apiUrl = 'http://localhost:8080';
	CONFIG.socketServer = 'http://localhost:4001';
}

export default CONFIG;
