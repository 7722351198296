import React, { Component } from 'react';

import CONFIG from 'config.js';

import './IdentificationDisplay.scss';

export default class IdentificationDisplay extends Component {

	displayIdentificationVerbose = ids => {
		const options = CONFIG.identificationOptions;
		return <span>{ids.map(id => options[id]).join(', ')}</span>
	};

	displayIdentification = ids => {
		return ids.map((id, index) => {
			return (
				<span key={index}>{this.idToLetter(id)}</span>
			);
		});
	};

	idToLetter = id => {
		return id.charAt(0).toUpperCase();
	};

	render() {
		const { verbose } = this.props;
		let ids = this.props.identification || [];
		let identification = ids;
		if(typeof ids === 'object' && ids !== null && !(ids instanceof Array) ){
			identification = Object.keys(ids).filter(id => ids[id]);
		}

		const identificationDisplay = verbose ? 
			this.displayIdentificationVerbose(identification) :
			this.displayIdentification(identification);

		return (
			<span className="IdentificationDisplay">
				{identificationDisplay}
			</span>
		);
	}
}
