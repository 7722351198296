import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';

import Identification from './Identification';
import LookingFor from './LookingFor';
import Age from './Age';
import Email from './Email';
import Answers from './Answers';
import Location from './Location';
import Distance from './Distance';
import Profile from './Profile';
import Chats from './Chats';
import Chatroom from './Chatroom';
import Feed from './Feed';
import DeleteAccount from './DeleteAccount';

import Login from './Login';
const basePath = '/app';

class LoggedInRouter extends Component {
	render () {
		const { user } = this.props;
		if(!(user && user.name)){
			console.log("LoggedInRouter log out");
			return <Login />;
		}
		return (
			<div className="LoggedInRouter">
				<Router>
					<Switch>
						<Route path={basePath + "/identification"} component={Identification} />
						<Route path={basePath + "/lookingfor"} component={LookingFor} />
						<Route path={basePath + "/age"} component={Age} />
						<Route path={basePath + "/email"} component={Email} />
						<Route path={basePath + "/answers"} component={Answers} />
						<Route path={basePath + "/location"} component={Location} />
						<Route path={basePath + "/distance"} component={Distance} />
						<Route path={basePath + "/profile"} component={Profile} />
						<Route path={basePath + "/messages/:id"} component={Chatroom} />
						<Route path={basePath + "/messages"} component={Chats} />
						<Route path={basePath + "/chats"} component={Chats} />
						<Route path={basePath + "/deleteaccount"} component={DeleteAccount} />
						<Route path={basePath + "/"} component={Feed} />
					</Switch>
				</Router>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LoggedInRouter);
