import { handleActions } from 'redux-actions';

import { SET_LOADING, SET_LOADED } from '../constants/App';

const defaultState = {
  loading: false,
  jobs: 0,
};

const reducerMap = {};

reducerMap[SET_LOADING] = (state, action) => ({
  ...state,
  loading: true,
  jobs: state.jobs + 1,
});

reducerMap[SET_LOADED] = (state, action) => {
  const jobs = state.jobs > 0 ? state.jobs - 1 : 0;

  return {
    ...state,
    loading: jobs > 0,
    jobs,
  };
};

const globalLoading = handleActions(reducerMap, defaultState);

export default globalLoading;
