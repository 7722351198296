import React, { Component } from 'react';
import { connect } from 'react-redux';

import QuestionForm from './QuestionForm';
import Button from './Common/Buttons/Button';

import './Questions.scss';

class Questions extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			activeQuestion: this.getActiveQuestion(),
			buttonText: 'Skip'
		};
	}

	componentWillReceiveProps(nextProps) {
		if(!this.state.activeQuestion){
			let question = this.getActiveQuestion(nextProps);
			if(question){
				this.setState({ activeQuestion: question });
			}
		}
	}

	getActiveQuestion = props => {
		props = props || this.props;
		const { questions } = props;
		if(this.state && this.state.activeQuestion){
			return this.state.activeQuestion;
		}else if(questions.length > 0){
			return this.getFirstUnansweredQuestion();
		}else{
			return null;
		}
	};

	getFirstUnansweredQuestion = () => {
		const { answers, questions } = this.props;
		const numAnswered = Object.keys(answers).length;
		if(numAnswered > 0 && numAnswered < 9){
			const sortedQuestions = questions.filter(
					q => {
						let answer = answers[q._id];
						return !answer || answer === '';
					}
				).sort( (a,b) => a.number - b.number );
			this.currentQuestion = sortedQuestions.length > 0 ? sortedQuestions[0].number : null;
		}
		return this.currentQuestion;
	};

	nextQuestion = () => {
		const { questions } = this.props;
		const activeQuestion = this.state.activeQuestion || this.currentQuestion || 1;
		// replace 9 with number of active questions (keep in global state)
		const nextQuestion = activeQuestion < questions.length ? activeQuestion + 1 : 1;
		this.setState({ activeQuestion: nextQuestion });
	};

	isAnswering = () => {
		this.setState({ buttonText: 'Next Question'});
	};


	stoppedAnswering = () => {
		this.setState({ buttonText: 'Skip'});
	};

	render() {
		const { questions } = this.props;
		const { buttonText } = this.state;
		const activeQuestion = this.state.activeQuestion || 1;
		const question = questions.filter(q => q.number === activeQuestion)[0] || {};

		return (
			<div className="Questions">
				<QuestionForm
					question={question} 
					isAnswering={this.isAnswering}
					stoppedAnswering={this.stoppedAnswering}
				/>
				<Button
					text={buttonText}
					classes='medium'
					onClick={this.nextQuestion}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	questions: state.Questions.questions,
	answers: state.Users.answers,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Questions);
