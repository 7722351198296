import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';
import TextArea from './Common/Inputs/TextArea';

import { setUserEmail } from '../actions/UserInfo';

import './Email.scss';

class Email extends Component {

	constructor(props) {
		super(props);
		this.state = { email: this.props.user.email || '' };
	}

	componentDidMount() {
		this.textArea.focus();
	}

	goBack = () => {
		this.props.history.goBack();
	};

	setEmail = email => {
		this.setState({email: email});
		this.hasChanged = true;
	};

	cleanInput = email => {
		return email.trim();
	};

	saveEmail = email => {
		if( this.hasChanged ){
			const { user } = this.props;

			email = this.cleanInput(email);

			this.props.setUserEmail({user: user, email: email});
		}
	};

	render() {

		return (
			<div className="Email">
				<h1 className="Email-header">What is your email address?</h1>
				<TextArea 
					key={"email"}
					value={this.state.email}
					onChange={this.setEmail}
					onBlur={this.saveEmail}
					inputRef={input => (this.textArea = input)}
				/>
				<Button
					text="Done"
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({
  setUserEmail: payload => dispatch(setUserEmail(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Email);
