import React, { Component } from 'react';

import PotentialMatch from './PotentialMatch';
import Button from './Common/Buttons/Button';

import './PotentialMatches.scss';

export default class PotentialMatches extends Component {

	onDislike = () => {
		this.props.onDislike();
	};

	onLike = () => {
		this.props.onLike();
	};

	render() {
		const { feed, current } = this.props;

		const suitors = feed.map((suitor, index) => {
			const visible = (index === current) ? 'visible' : '';
			return (
				<span key={index} className={"PotentialMatches-match " + visible}>
					<PotentialMatch matchUser={suitor} />
				</span>
			);
		});

		return (
			<div className="PotentialMatches">
				{suitors}
				<div className="PotentialMatches-buttons">
					<Button
						text="Dislike"
						onClick={this.onDislike}
					/>
					<Button
						text="Like"
						onClick={this.onLike}
					/>
				</div>
			</div>
		);
	}
}
