import React, { Component } from 'react';
import { connect } from 'react-redux';

import Logo from './Logo';

import './GlobalLoader.scss';

class GlobalLoader extends Component {

	state = { loading: false, wasLoading: false };

	componentWillReceiveProps(nextProps) {
		if (nextProps.loading !== this.props.loading) {
			if(this.state.loading === true && nextProps.loading === false){
				this.setState({wasLoading: true});
			}
			this.setState({loading: nextProps.loading});
		}
	}

	render() {
		const { loading, wasLoading } = this.state;
		const loadingClass = loading ? 'loading' : wasLoading ? 'loading-finished' : '';

		return (
			<div className={"GlobalLoader " + loadingClass}>
				<Logo />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.GlobalLoading.loading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GlobalLoader);
