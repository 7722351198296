import React, { Component } from 'react';

export default class Checkmark extends Component {
	render() {
		return (
			<span className='Checkmark'>
				✓
			</span>
		);
	}
}
