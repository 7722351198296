
import { eventChannel } from 'redux-saga';
import { call, put, takeEvery, take } from 'redux-saga/effects';

import {
	getSocket,
	closeSocket,
	socketHeaders,
} from '../services/SocketHelper';

import {
	newMatches,
} from '../actions/Matches';

import {
	getUserChats,
} from '../actions/UserInfo';

import {
	INIT_MATCHES_CHANNEL,
	CLOSE_MATCHES_CHANNEL,
	SUBSCRIBE_TO_UPDATES,
	SEND_USER_LIKES,
	NEW_MATCHES,
} from '../constants/Actions';


const subscribeToUpdates = user_id => {
	const socket = getSocket();
	// console.log("Subscribing to updates: ", user_id);
	socket.emit("SubscribeToUpdates", user_id);
};

const sendUserLikes = data => {
	const socket = getSocket();
	socket.emit("UserLikes", data, socketHeaders());
};

function createEventChannelWorker() {
	const socket = getSocket();
	return eventChannel(emit => {
		socket.on("NewMatches", matchesRecord => {
			return emit(matchesRecord);
		});
		return () => {
			closeSocket();
		};
	});
}

function* initMatchesChannelWorker() {
	const channel = yield call(createEventChannelWorker);
	while (true) {
		const matchesRecord = yield take(channel);
		yield put(newMatches(matchesRecord));
	}
}

function* closeMatchesChannelWorker() {
	yield call(closeSocket);
}

function* subscribeToUpdatesWorker(action) {
	try {
		yield call(subscribeToUpdates, action.payload);
	} catch (e) {
		console.error("Error subscribing to updates:");
		console.error(e);
	}
}

function* sendUserLikesWorker(action) {
	try {
		yield call(sendUserLikes, action.payload);
	} catch (e) {
		console.error("Error sending user likes:");
		console.error(e);
	}
}

function* newMatchesWorker(action) {
	try {
		const payload = action.payload || {};
		if(payload.user_id){
			yield put(getUserChats(payload.user_id));
		}
	} catch (e) {
		console.error("Error getting user chats after getting new matches:");
		console.error(e);
	}
}

function* chatsListener() {
	yield takeEvery(INIT_MATCHES_CHANNEL, initMatchesChannelWorker);
	yield takeEvery(CLOSE_MATCHES_CHANNEL, closeMatchesChannelWorker);
	yield takeEvery(SUBSCRIBE_TO_UPDATES, subscribeToUpdatesWorker);
	yield takeEvery(SEND_USER_LIKES, sendUserLikesWorker);
	yield takeEvery(NEW_MATCHES, newMatchesWorker);
}

export default chatsListener;
