import { call, put, takeEvery } from 'redux-saga/effects';

import {
	setUserInfo,
	setAuthToken,
} from '../actions/UserInfo';

import {
	GET_USER,
	GET_USER_BY_ID_AND_TOKEN,
	SAVE_USER,
	DELETE_ACCOUNT,
	REPORT_USER,
	GET_AUTH_TOKEN,
	SAVE_AUTH_TOKEN,
} from '../constants/Actions';

import * as UsersAPI from 'services/UsersAPI';
import * as ReportUsersAPI from 'services/ReportUsersAPI';
import * as AuthToken from 'services/AuthToken';

let getUserAttempts = 0;

function* deleteUserWorker(action) {
	try {
		yield call(UsersAPI.deleteUser, action.payload);
		yield call(AuthToken.deleteAuthToken);
		// yield call(window.FB.logout);
	} catch (e) {
		console.error("Error deleting user");
		console.error(e);
	}
}

function* reportUserWorker(action) {
	try {
		yield call(ReportUsersAPI.reportUser, action.payload);
	} catch (e) {
		console.error("Error reporting user");
		console.error(e);
	}
}

function* saveUserWorker(action) {
	console.log("saveUserWorker");
	try {
		const resp = yield call(UsersAPI.submitUser, action.payload);
		const token = resp.headers.get('x-auth-token');
		if(token){
			yield call(AuthToken.saveAuthToken, token);
		}
		yield* fetchUserWorker(action);
	} catch (e) {
		console.error("Error saving user");
		console.error(e);
	}
}

function* fetchUserWorker(action) {
	console.log("fetchUserWorker");
	try {
		const user = yield call(UsersAPI.getUser, action.payload.userID);
		if(user){
			yield put(setUserInfo(user));
		}else if(getUserAttempts < 3){
			console.log("try again");
			console.log(getUserAttempts);
			getUserAttempts++;
			yield* saveUserWorker(action);
			
		}
	} catch (e) {
		console.error("Error finding user");
		console.error(e);
	}
}

function* fetchUserByIdAndTokenWorker(action) {
	console.log("fetchUserByIdAndTokenWorker");
	try {
		const token = action.payload.token;
		if(token){
			yield call(AuthToken.saveAuthToken, token);
		}
		yield* fetchUserWorker(action);
	} catch (e) {
		console.error("Error finding user by token");
		console.error(e);
	}
}

function* fetchAuthTokenWorker(action) {
	console.log("fetchAuthTokenWorker");
	try {
		const authToken = yield call(AuthToken.getAuthToken);
		yield put(setAuthToken(authToken));
	} catch (e) {
		console.error("Error getting auth token");
		console.error(e);
	}
}

function* saveAuthTokenWorker(action) {
	console.log("saveAuthTokenWorker");
	try {
		yield call(AuthToken.saveAuthToken, action.payload);
	} catch (e) {
		console.error("Error saving auth token");
		console.error(e);
	}
}

function* usersListener() {
	yield takeEvery(GET_USER, fetchUserWorker);
	yield takeEvery(GET_USER_BY_ID_AND_TOKEN, fetchUserByIdAndTokenWorker);
	yield takeEvery(SAVE_USER, saveUserWorker);
	yield takeEvery(DELETE_ACCOUNT, deleteUserWorker);
	yield takeEvery(REPORT_USER, reportUserWorker);
	yield takeEvery(GET_AUTH_TOKEN, fetchAuthTokenWorker);
	yield takeEvery(SAVE_AUTH_TOKEN, saveAuthTokenWorker);
}

export default usersListener;
