import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getQuestions } from '../actions/Questions';

class LoadQuestions extends Component {

	componentDidMount() {
		this.props.getQuestions();
	}

	render() {
		return (<div className="LoadQuestions" />);
	}
}

const mapStateToProps = state => ({
	questions: state.Questions.questions,
});

const mapDispatchToProps = dispatch => ({
	getQuestions: () => dispatch(getQuestions()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LoadQuestions);
