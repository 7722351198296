import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/location/';

export function getUserLocation(user_id) {
	const params = encodeURIComponent(user_id);
	const endpoint = CONFIG.apiUrl + path + params;
	return RequestHelper.getRequest(endpoint);
}

export function submitUserLocation(user, location) {
	const endpoint = CONFIG.apiUrl + path;
	let locationObj = { timestamp: location.timestamp };
	let coords = location.coords;
	locationObj.coords = { 
		latitude: coords.latitude, 
		longitude: coords.longitude,
		accuracy: coords.accuracy,
	};
	locationObj.city = location.city;
	locationObj.state = location.state;
	const body = JSON.stringify({user_id: user._id, location: locationObj});
	return RequestHelper.putRequest(endpoint, body);
}