import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const likesPath = '/api/likes/';
const matchesPath = '/api/matches/';

export function getUserMatches(user_id) {
	const params = encodeURIComponent(user_id);
	const endpoint = CONFIG.apiUrl + matchesPath + params;
	return RequestHelper.getRequest(endpoint);
}

export function setUserLike(user1, user2, likesDislikes = true) {
	const endpoint = CONFIG.apiUrl + likesPath;
	const body = JSON.stringify({user1: user1, user2: user2, likes: likesDislikes });
	return RequestHelper.putRequest(endpoint, body);
}

