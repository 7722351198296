import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserInfo from './UserInfo';
import ReportUser from './ReportUser';
import Button from './Common/Buttons/Button';

import './PotentialMatch.scss';

class PotentialMatch extends Component {

	constructor(props) {
		super(props);
		this.getNewAnswers(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.matchUser !== this.props.matchUser) {
			console.log("nextProps:");
			console.log(nextProps.matchUser);
			this.getNewAnswers(nextProps);
		}
	}

	getNewAnswers = props => {
		const matchUser = props.matchUser || { userAnswers: [] };
		const questions = props.questions || [];

		this.answers = matchUser.userAnswers.map(answer => {
			let question = questions.filter(question => {
				return question._id === answer.question && question.active === true;
			});
			question = (question.length > 0) ? question[0] : {};
			answer.questionObj = question;
			return answer;
		}).filter(answer => Object.keys(answer.questionObj).length > 0);

		this.hasSeen = this.answers.reduce(
			( accumulator, current ) => {
				accumulator[current._id] = 0;
				return accumulator;
			},
			{}
		);
	};

	fakeAnswer = () => {
		return {
			questionNumber: 0,
			questionText: '',
			answer: '',
		};
	};

	getRandomAnswer = () => {

		let leastSeen = 0;
		if(Object.keys(this.hasSeen).length	> 1){
			leastSeen = Object.values(this.hasSeen).reduce((a, b) => Math.min(a, b));
		}

		const answers = this.answers.filter(answer => this.hasSeen[answer._id] === leastSeen);
		if(answers.length < 1){
			return this.fakeAnswer();
		}

		const randomKey = Math.floor(Math.random() * answers.length);
		const answer = answers[randomKey];
		if(!answer){
			return this.fakeAnswer();
		}

		this.hasSeen[answer._id]++;

		const question = answer.questionObj;

		return {
			questionNumber: question.number,
			questionText: question.text,
			answer: answer.answer,
		};
	};

	refreshQuestion = () => {
		this.forceUpdate();
	}

	render() {
		const { matchUser } = this.props;

		const { answer, questionNumber, questionText } = this.getRandomAnswer();

		return (
			<div className="PotentialMatch">
				<UserInfo user={matchUser} />
				<div className="PotentialMatch-question">
					<div className="PotentialMatch-question-header">
						Question #{ questionNumber }
						<Button
							text="See Another"
							classes='small'
							onClick={this.refreshQuestion}
						/>
					</div>
					<div className="PotentialMatch-question-text">
						{ questionText }
					</div>
					<div className="PotentialMatch-answer-text">
						{ answer }
					</div>
					<ReportUser accusedUser={matchUser}/>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	questions: state.Questions.questions,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PotentialMatch);
