import React, { Component } from 'react';

import NavButton from './Common/Buttons/NavButton';
import MessagesNavButton from './MessagesNavButton';

import './Header.scss';

export default class Header extends Component {
	render() {
		const { profile, messages, feed } = this.props;
		const profileSection = profile ? (<NavButton to='/app/profile' text='Your Profile' />) : null;
		const messagesSection = messages ? (<MessagesNavButton />) : null;
		const feedSection = feed ? (<NavButton to='/app/feed' text='View Matches' />) : null;

		return (
			<div className="Header">
				{ profileSection }
				{ messagesSection }
				{ feedSection }
			</div>
		);
	}
}
