import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from './Common/Buttons/Button';
import IntegerInput from './Common/Inputs/IntegerInput';

import { setUserAge } from '../actions/UserInfo';

import './Age.scss';

class Age extends Component {

	constructor(props) {
		super(props);
		this.state = { age: this.props.user.age || '' };
	}

	componentDidMount() {
		this.textArea.focus();
	}

	goBack = () => {
		this.props.history.goBack();
	};

	setAge = age => {
		this.setState({age: age});
		this.hasChanged = true;
	};

	checkBounds = age => {
		return Math.max(Math.min(100, parseInt(age, 10)), 18);
	};

	saveAge = age => {
		if( this.hasChanged ){
			const { user } = this.props;

			age = this.checkBounds(age);

			this.props.setUserAge({user: user, age: age});
		}
	};

	render() {

		return (
			<div className="Age">
				<h1 className="Age-header">How old are you?</h1>
				<IntegerInput 
					key={"age"}
					value={this.state.age}
					onChange={this.setAge}
					onBlur={this.saveAge}
					inputRef={input => (this.textArea = input)}
				/>
				<Button
					text="Done"
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
});

const mapDispatchToProps = dispatch => ({
  setUserAge: payload => dispatch(setUserAge(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Age);
