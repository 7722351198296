import { combineReducers } from 'redux';

import Users from './Users';
import Questions from './Questions';
import Chats from './Chats';
import Matches from './Matches';
import GlobalLoading from './GlobalLoading';
import Tokens from './Tokens';

import { CLEAR_USER } from '../constants/Actions';

const appReducer = combineReducers({
  Users,
  Questions,
  Chats,
  Matches,
  GlobalLoading,
  Tokens,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
