import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './Header';
import Chat from './Chat';

import { clearUnreadMessages } from '../actions/Chats';
import { clearUnseenMatches } from '../actions/UserInfo';

import './Chats.scss';

class Chats extends Component {

	componentDidMount() {
		this.props.clearUnreadMessages();
		this.props.clearUnseenMatches();
	}

	render() {
		const { chats } = this.props;
		const chatBlocks = Object.values(chats).map((chat, index) => {
			const messages = chat.messages || [];
			const otherUser = chat.otherUser || {};
			return (
				<Chat 
					key={index} 
					id={chat._id} 
					user={otherUser} 
					messages={messages}
				/>
			);
		});

		return (
			<div className="Chats">
				<Header profile={true} feed={true} />
				<div className="Chats-content">
					<h1>Messages</h1>
					{chatBlocks}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	chats: state.Chats.chats,
});

const mapDispatchToProps = dispatch => ({
	clearUnreadMessages: () => dispatch(clearUnreadMessages()),
	clearUnseenMatches: () => dispatch(clearUnseenMatches()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Chats);
