
const tokenKey = 'id_token';

export function getAuthToken() {
	return localStorage.getItem(tokenKey);
}

export function saveAuthToken(token) {
	if(token){
		console.log("storing token:");
		console.log(token);
		localStorage.setItem(tokenKey, token);
	}
}

export function deleteAuthToken() {
	localStorage.removeItem(tokenKey);
}