import React, { Component } from 'react';
import { connect } from 'react-redux';

import Selectable from './Common/Selectable';
import Button from './Common/Buttons/Button';

import { saveUserIdentification } from '../actions/UserInfo';

import CONFIG from 'config.js';

import './Identification.scss';

class Identification extends Component {
	constructor(props) {
		super(props);
		this.options = CONFIG.identificationOptions;
	}

	onSelectChange = name => {
		const { user, identification } = this.props;

		const value = !identification[name];
		if( name === 'masculine' && value === true){
			this.submitChange(user, 'feminine', false);
		}
		if( name === 'feminine' && value === true){
			this.submitChange(user, 'masculine', false);
		}
		this.submitChange(user, name, value);
	};

	submitChange = (user, name, value) => {
		this.props.saveUserIdentification({ user: user, identification: name, value: value });
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		let options = Object.keys(this.options).map((key, index) => {
			let value = this.options[key];
			return (
				<span key={index} className="Identification-option">
					<Selectable 
						selected={this.props.identification[key]}
						onSelectChange={this.onSelectChange}
						name={key}
					>
						{value}
					</Selectable>
				</span>
			);
		});
		return (
			<div className="Identification">
				<h1 className="Identification-header">What best describes you?</h1>
				{ options }
				<Button
					text="Done"
					onClick={this.goBack}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.Users.user,
	identification: state.Users.identification,
});

const mapDispatchToProps = dispatch => ({
  saveUserIdentification: payload => dispatch(saveUserIdentification(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Identification);
