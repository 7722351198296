import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

const path = '/api/lookingfor/';

export function getUserLookingFor(user_id) {
	const params = encodeURIComponent(user_id);
	const endpoint = CONFIG.apiUrl + path + params;
	return RequestHelper.getRequest(endpoint);
}

export function submitUserLookingFor(user, lookingfor, value) {
	const endpoint = CONFIG.apiUrl + path;
	const body = JSON.stringify({user_id: user._id, lookingfor: lookingfor, value: value});
	return RequestHelper.putRequest(endpoint, body);
}