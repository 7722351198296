import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UserInfo from './UserInfo';

import './Chat.scss';

class Chat extends Component {
	render() {
		const { id, user, messages, lastMessages } = this.props;
		let lastMessage = (lastMessages[id]) ?
			lastMessages[id].message : (messages.length > 0) ?
			messages[messages.length - 1].message : 'Send them a message!';
		return (
			<NavLink className="Chat" to={'/messages/' + id} >
				<UserInfo user={user} />
				<span className="Chat-message">
					{lastMessage}
				</span>
			</NavLink>
		);
	}
}


const mapStateToProps = state => ({
	lastMessages: state.Chats.lastMessages,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Chat);